@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

html,
body,
.form,
#root {
  width: 100%;
  height: 100%;
}

iframe {
  border-radius: 0 !important;
}

footer {
  display: none;
}

.background-image {
  background-image: url("../images/bakgrunnstekstur-mobil.jpg");
  background-repeat: no-repeat;
  background-position: right top;
}

.background-image {
  background-image: url("../images/bakgrunnstekstur-mobil.jpg");
  background-size: contain;
  background-repeat: repeat;
}

.base-grid {
  @apply grid grid-cols-12 gap-x-5 lg:gap-x-8;
}

.base-container {
  @apply w-full mx-auto px-[1.3rem] lg:px-8 max-w-[1600px];
}

.text-line {
  line-height: 90%;
  font-variation-settings: "wght" 900, "ital" 1;
  font-family: "Roboto Flex", sans-serif;
  text-transform: uppercase;
}

.text-line span:nth-child(1) {
  animation-delay: 0.3s;
}
.text-line span:nth-child(2) {
  animation-delay: 0.4s;
}
.text-line span:nth-child(3) {
  animation-delay: 0.5s;
}
.text-line span:nth-child(4) {
  animation-delay: 0.6s;
}
.text-line span:nth-child(5) {
  animation-delay: 0.7s;
}
.text-line span:nth-child(6) {
  animation-delay: 0.8s;
}
.text-line span:nth-child(7) {
  animation-delay: 0.9s;
}
.text-line span:nth-child(8) {
  animation-delay: 1s;
}
.text-line span:nth-child(9) {
  animation-delay: 1.1s;
}
.text-line span:nth-child(10) {
  animation-delay: 1.2s;
}
.text-line span:nth-child(11) {
  animation-delay: 1.3s;
}
.text-line span:nth-child(12) {
  animation-delay: 1.4s;
}
.text-line span:nth-child(13) {
  animation-delay: 1.5s;
}
.text-line span:nth-child(14) {
  animation-delay: 1.6s;
}

.text-line span {
  display: inline-block;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: change;
  transition-timing-function: ease-in-out;
}

.text-line--2 span {
  animation-duration: 6s;
  animation-name: change2;
}

.text-line--3 span {
  animation-duration: 10s;
  animation-name: change3;
}

@keyframes change {
  0%,
  100%,
  10%,
  90% {
    font-variation-settings: "wght" 900, "ital" 1;
  }
  50% {
    font-variation-settings: "wght" 600, "ital" 0;
  }
}

@keyframes change2 {
  0%,
  100%,
  25%,
  75% {
    font-variation-settings: "wght" 900, "ital" 1;
  }
  50% {
    font-variation-settings: "wght" 600, "ital" 0;
  }
}

@keyframes change3 {
  0%,
  100%,
  20%,
  80% {
    font-variation-settings: "wght" 1000, "ital" 1;
  }
  50% {
    font-variation-settings: "wght" 600, "ital" 0;
  }
}
